.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* Hide the navbar in full-screen mode */

:-webkit-full-screen .navbar,
:-moz-full-screen .navbar {
  display: none;
}

.App-header {
  background-color: #050505;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes border-travel {
  0% {
    border-color: red;
  }
  25% {
    border-color: orange;
  }
  50% {
    border-color: yellow;
  }
  75% {
    border-color: pink;
  }
  100% {
    border-color: red;
  }
}

.animate-border-travel {
  animation: border-travel 8s linear infinite;
}





/* Moving border lighting effect */
.glow-border {
  position: relative;
}

.glow-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
 
  background: linear-gradient(270deg, rgba(255, 249, 248, 0.5), rgba(255, 249, 248, 0.1), rgba(255, 249, 248, 0.5));
  filter: blur(10px);
  animation: glow-animation 3s linear infinite;
}

@keyframes glow-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}






/* tilt effect for the testimonials webpage component */
.outer-container {
  position: relative;
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  border-radius: 5px;
  border: 2px solid transparent;
  background: linear-gradient(145deg, #0d0c0a, #0d0c0a);
  perspective: 1000px;
}

.outer-container::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: 5px;
  background: linear-gradient(145deg, #f97316, #a855f7);
}





/* notifications */
/* Soft glow for icons and headings */
.soft-glow {
  filter: drop-shadow(0 0 6px rgba(184, 216, 216, 0.6));
}

/* Pastel button styling */
.pastel-button {
  background: linear-gradient(90deg, #C8E4E4, #E9D2D2);
  color: #0d0c0a;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}
.pastel-button:hover {
  box-shadow: 0 6px 12px rgba(255, 255, 255, 0.15);
}

/* Glassmorphism effect for cards */
.backdrop-blur-lg {
  background: rgba(13, 12, 10, 0.6);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
